.test-result-row {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    padding: 16px 0;
    border-bottom: solid 1px #ddd;
}

.test-result-row:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.test-result {
    font-size: 17px;
    font-weight: bold;
}

.test-result-date {
    font-weight: bold;
    text-align: right;
    margin-bottom: 8px;
}

.test-result-time {
    font-size: 12px;
    color: #999;
    text-align: right;
}
