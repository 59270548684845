body {
    font-family: ui-rounded, -apple-system, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
}

.test-results {
    padding-top: env(safe-area-inset-top);
}

.test-result-list {
    padding: 0 16px;
}
