.navigation-bar {
    display: grid;
    grid-template-columns: 15px 40px auto 40px 15px;
    align-items: center;
    padding: 16px;
    font-size: 18px;
}

.navigation-bar-back-icon {
    color: #EF6C00;
    font-size: 0.9em;
}

.navigation-bar-back {
    color: #EF6C00;
}

.navigation-bar-title {
    text-align: center;
    font-weight: bold;
}
