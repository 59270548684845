body {
    font-family: ui-rounded, -apple-system, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
}

.home {
    padding-top: env(safe-area-inset-top);
}

.home-header-wrapper {
    background: #FFF;
    box-shadow: 0 9px 9px #ddd;
}

.home-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 30px 15px 15px 15px;
}

.home-logo img {
    height: 45px;
}

.home-header-text-highlighted {
    color: #EF6C00;
    font-size: 24px;
}

.home-action {
    font-size: 17px;
}

.home-sub-actions {
    padding-top: 16px;
    text-align: center;
}

.home-sub-action {
    display: block;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
}

.home-sub-action:last-child {
    margin-bottom: 0;
}

.home-participant-identifier {
    text-align: center;
    font-size: 15px;
    padding: 0 16px 16px;
}

.home-body {
    padding: 16px;
    font-size: 15px;
}

.home-body h2 {
    margin-top: 0;
    font-size: 21px;
}

.home-body h2:last-child {
    margin-bottom: 0;
}

.home-body-section {
    padding: 16px;
    border-radius: 10px;
    background: #fff;
    color: #000;
    box-shadow: 0 0 3px #aaa;
    margin-bottom: 16px;
}

.home-body-section:last-child {
    margin-bottom: 0;
}

.home-body-section .mdhui-action {
    padding: 8px 16px 8px 0;
}

.home-body-section .mdhui-action .indicator {
    right: 0;
}

.home-body-section .test-result-list {
    padding: 0;
}

.home-survey {
    padding: 16px 0;
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    cursor: pointer;
    border-bottom: solid 1px #ddd;
}

.home-survey:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.home-survey-title {
    font-size: 17px;
    font-weight: bold;
}

.home-survey-description {
    font-size: 15px;
    color: #555;
}

.home-survey-nav {
    font-size: 15px;
    color: #c7c7cc;
}

.home-app-download {
    display: grid;
    grid-template-columns: max-content max-content auto;
    grid-column-gap: 4px;
}

.home-app-download img {
    height: 44px;
}

.home-hotline-phone {
    font-size: 32px !important;
    margin-bottom: 8px;
}

.home-fitbit p {
    font-size: 15px;
}

.home-test-header {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: baseline;
}

.home-test-view-all {
    font-size: 15px;
    font-weight: normal;
}

.home-schedule-select {
    width: 100%;
    font-size: 17px;
    padding: 8px;
    margin-bottom: 0;
}

.home-footer {
    text-align: center;
    max-width: 600px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 16px 16px;
}

.home-footer a {
    color: #555;
    text-decoration: none;
}

.home-footer a:hover {
    color: #429bdf;
}

.home-footer-credits {
    font-size: 12px;
    padding-top: 16px;
}

.home-footer-company {
    font-size: 15px;
    padding-top: 16px;
    padding-bottom: 16px;
}
