a {
    color: #F58021;
    text-decoration: none;
    cursor: pointer;
}

.cta {
    padding: 16px;
}

.cta-title {
    font-size: 24px;
    margin: 0 0 16px;
}

.cta-text {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 16px;
    line-height: 28px;
}

.cta-button-primary {
    display: block;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid #F58021;
    background: #F58021;
    color: #FFF;
    text-decoration: none;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
}

.cta-button-secondary {
    display: block;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid #F58021;
    color: #F58021;
    text-decoration: none;
    font-size: 21px;
    text-align: center;
    margin-top: 16px;
}

.loading-indicator {
    text-align: center;
    color: #bbb;
    padding: 16px;
}

.color-warning {
    color: #c4291c;
}

.color-good {
    color: #5db37e;
}

.color-caution {
    color: #EF6C00;
}